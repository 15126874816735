import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "design-spec"
    }}>{`Design spec`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1120px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "89.64285714285715%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAABxElEQVQ4y2P4jxX8/ff/z99/P3/9B6I/f/7//fv/HxZVDBi6/v368+fn39/Igr///vsN1E9Y858/b//8mbDrUFZtbUp1ZWp1dUppadGsWQ++fQPK/vv3D6fmf2D86f//wu61KlKq8nIyMlLSSpKSFqGh1798gbgLv+Z/QFWJHe1G+gbWNjZ6+voaSkr2sbE3idT8Gai5vdtQ18DC0tLYxERBSsoyLOzm16/EaAY5O7N3uqGmiYGhPtBmA21t54QEIm2GaJ5ipG2srqGmqqamparqmpR0g3jNGZ3T7bVDVdSU1NU1FKWlrcLDSbA5f8ICK2MbC0tzM3MzIx1t16REYjV//P+vYuIWY00rHX0dHX0jdWV1m8goYkMbGKzRLQ2K8rKaWtrqmtpKsvLEOxsUz3EtHXLSMmoa6uqamooyMlYREcRGFdDZpd2b9OWN1JTlFWVlVCRE7SPCbn75TITm///f/f9fumZzQH5hUGmJR265R15V5NT5N79+I0rzy58/O/Ycq1qzqmrdhvA1ny2X/bdc8fvSh1+QnIovVwHB19+/b318f/v9+1vvP1z79PP65z+XP/38+PsP3HR8mqHgz9//hAAANrx/44pvTBMAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Design spec for the order summary template",
        "title": "Design spec for the order summary template",
        "src": "/static/933129e7db1aaa913da11f59ce8bf830/026f9/specs_ordersummary.png",
        "srcSet": ["/static/933129e7db1aaa913da11f59ce8bf830/d6747/specs_ordersummary.png 288w", "/static/933129e7db1aaa913da11f59ce8bf830/09548/specs_ordersummary.png 576w", "/static/933129e7db1aaa913da11f59ce8bf830/026f9/specs_ordersummary.png 1120w"],
        "sizes": "(max-width: 1120px) 100vw, 1120px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Design spec for the order summary template</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      